import React, { Suspense, lazy } from "react";
import { useTheme } from "srcm/hooks/useTheme";
// import AppDesktop from "";
const AppDesktop = lazy(() => import("./AppDesktop"));
const AppMobile = lazy(() => import("./srcm/AppMobile"));

function App() {
  useTheme();
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  return (
    <Suspense fallback={<div></div>}>
      {isMobile ? <AppMobile /> : <AppDesktop />}
    </Suspense>
  );
}

export default App;
